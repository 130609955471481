<template>
  <div class="goods-list">
    <el-row>
      <el-col>
        <el-form :inline="true" :model="search" class="demo-form-inline" size="mini">
          <el-form-item label="商品标题">
            <el-input v-model="search.title" placeholder="请输入要查询的标题" clearable></el-input>
          </el-form-item>
          <el-form-item label="商品状态">
            <el-select v-model="search.status" placeholder="状态">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="出售中" :value="1"></el-option>
              <el-option label="仓库中" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border size="mini" v-loading="loading">
      <el-table-column prop="id" label="ID" width="50" fixed="left"></el-table-column>
      <el-table-column prop="image" label="图片" width="100">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px;display: flex;justify-content: center;" :src="scope.row.image" fit="cover">
            <div slot="error" class="image-slot" style="margin: auto;">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        width="180">
        <template slot-scope="scope">
          <el-tag size="mini" type="success" v-if="scope.row.status === 1">出售中</el-tag>
          <el-tag size="mini" v-if="scope.row.status === 2">仓库中</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="begin_time"
        label="定时上架时间"
        width="200">
      </el-table-column>
      <el-table-column
        prop="end_time"
        label="定时下架时间"
        width="200">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="200">
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="更新时间"
        width="200">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
          <el-popconfirm title="要删除吗？" @confirm="handleDelete(scope.$index, scope.row.id)">
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :page-size="10" :current-page.sync="page" layout="prev, pager, next" :total="goods_count" @current-change="handle_page_current" >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'GoodsList',
  data () {
    return {
      loading: false,
      page: 1,
      goods_count: 0,
      search: { title: '', status: 0 },
      tableData: []
    }
  },
  methods: {
    onSearch () {
      this.getGoodsData(1)
    },
    handleEdit (gid) {
      this.$router.push({ path: `/goods_edit/${gid}` })
    },
    handleDelete (index, id) {
      var that = this
      this.axios.delete('admin/goods/' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message.success(response.data.msg)
            that.tableData.splice(index, 1)
          }
        })
    },
    getGoodsData (page) {
      this.loading = true
      var that = this
      this.axios.get('admin/goods?page=' + page, {
        params: that.search
      })
        .then(function (response) {
          that.loading = false
          that.tableData = response.data.list
          that.page = response.data.page
          that.goods_count = response.data.count
        })
    },
    handle_page_current (e) {
      this.getGoodsData(e)
    }
  },
  mounted () {
    this.getGoodsData(1)
  }
}
</script>

<style>

</style>
